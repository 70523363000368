import Layout from "../components/Layout/Layout"
import Page from "../components/Page/Page"
import { PageResult } from "../lib/types"

const PageTemplate = ({ pageContext }: { pageContext: PageResult }) => (
  <Layout
    showHeader={!!pageContext.pageComponents.showheader}
    footerMenu={pageContext.menus.footer}
    contact={pageContext.settings.contact}
  >
    <Page {...pageContext} />
  </Layout>
)

export default PageTemplate
